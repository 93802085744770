<template>
  <div>
    <ChoosePlatform
      @setPlatform="setCurrentPlatform"
      v-if="selectedPlatform === null"
    />
    <div v-else class="content content_in">
      <ArbitragePlatform
        @createPlatform="createPlatform"
        :landings="landings"
        v-if="selectedPlatform === 0"
      />
      <SitePlatform @createPlatform="createPlatform" v-else />
    </div>
    <notifications group="error" />
  </div>
</template>

<script>
import ServiceSubAccs from "@/api/subaccsService";
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_section.scss";
import ChoosePlatform from "@/components/ChoosePlatform.vue";
import ArbitragePlatform from "@/components/ArbitragePlatform.vue";
import SitePlatform from "@/components/SitePlatform.vue";

export default {
  name: "CreatePlatform",
  components: {
    ChoosePlatform,
    ArbitragePlatform,
    SitePlatform,
  },
  data() {
    return {
      landings: [],
      selectedPlatform: null,
    };
  },
  mounted() {
    ServiceSubAccs.getLandings().then((res) => {
      if (res.status === 200) {
        this.landings = res.data;
      }
    });
  },
  methods: {
    setCurrentPlatform(val) {
      this.selectedPlatform = val;
    },
    createPlatform(payload) {
      payload.site = this.selectedPlatform;
      ServiceSubAccs.createSubAcc(payload).then((res) => {
        if (res) {
          if (res.status === 200) {
            this.$notify({
              group: "error",
              title: this.$t("sent_success"),
              text: res.message,
              ignoreDuplicates: true,
              type: "success",
            });
            this.$emit("createdTicket", res.data.id);
            this.$emit("closeModal");
            if(this.selectedPlatform === 1)
            this.$router.push(`/platform-settings/${res.data.id}`);
            else if(this.selectedPlatform === 0) 
            this.$router.push('/platforms')
          } else {
            this.$notify({
              group: "error",
              title: this.$t("sent_error"),
              text: res.message,
              ignoreDuplicates: true,
              type: "error",
            });
          }
        }
      });
    },
  },
};
</script>



